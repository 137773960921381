import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"


import Hero from "../components/hero"
import tarievenStyles from "../components/tarieven.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const pageTitle = "Tarieven"

const TarievenPage = () => (
<Layout>
<SEO title={pageTitle} />


<Hero>
<h1 class="title has-text-light">
	{pageTitle}
</h1>
</Hero>
<section class="section has-background-neutral-10">
	<div class="container">
		<h2 class="title has-text-centered">
			Pakketten
		</h2>
		<div class="tile is-ancestor">

			<div class="tile is-4 is-parent">
				<div class="tile is-child box">
					<p class="title is-4">Proefles</p>
					<p class="subtitle">Gratis</p>
					<ul className={tarievenStyles.tarievenList}>
						<li>
							<FontAwesomeIcon className={tarievenStyles.tarievenIcon} icon="check" color="green" />
							Proefles 75 minuten
						</li>
					</ul>
					<a class="button is-primary-4" href="#contact">Inschrijven</a>
				</div>
			</div>

			<div class="tile is-4 is-parent">
				<div class="tile is-child box">
					<p class="title is-4">Pakket A</p>
					<p className={`subtitle ${tarievenStyles.tarievenPrice}`}>1200</p>
					<ul className={tarievenStyles.tarievenList}>
						<li>
							<FontAwesomeIcon className={tarievenStyles.tarievenIcon} icon="check" color="green" />
							16 rijlessen van 90 minuten
						</li>
						<li>
							<FontAwesomeIcon className={tarievenStyles.tarievenIcon} icon="check" color="green" />
							1 praktijkexamen
						</li>
					</ul>
					<a class="button is-primary-4" href="#contact">Inschrijven</a>
				</div>
			</div>

			<div class="tile is-4 is-parent">
				<div class="tile is-child box">
					<p class="title is-4">Pakket B</p>
					<p className={`subtitle ${tarievenStyles.tarievenPrice}`}>1570</p>
					<ul className={tarievenStyles.tarievenList}>
						<li>
							<FontAwesomeIcon className={tarievenStyles.tarievenIcon} icon="check" color="green" />
							22 rijlessen van 90 minuten
						</li>
						<li>
							<FontAwesomeIcon className={tarievenStyles.tarievenIcon} icon="check" color="green" />
							1 praktijkexamen
						</li>
					</ul>
					<a class="button is-primary-4" href="#contact">Inschrijven</a>
				</div>
			</div>

		</div>
	</div>
</section>
</Layout>
)

export default TarievenPage
